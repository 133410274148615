import React, { useEffect } from "react";
import { MainContainer } from "../../layouts/main container/mainContainer";
// import { Menu } from "../../Components/Menu";
import { AboutCart } from "./AboutCart.component";
import { NewsSection } from "./NewsSection.component";
import { GallerySection } from "./GallerySection.component";
import { BlogSection } from "./BlogSection.component";
import { TvSection } from "./TvSection.component";
import { FeedbackSection } from "./FeedbackSection.component";
import { Banner } from "./Banner.component";
import { ContactForm } from "../../components/ContactForm.component";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";


const fakeDataNewsCart = [
	{
		id: 3,
		title: "همایش درخصوص رژه پوتریکا",
		body: ` ورم ایپسوم متن ساختگی با تولید سادگی نامفهم
	از صنعت چاپ.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ  ,`,
	},

	{
		id: 2,
		created_data: "2024-04-30T14:44:51.000000Z",
		title: "مراسم سوارکارن سیاه",
		body: ` ورم ایپسوم متن ساختگی با تولید سادگی نامفهم
	از صنعت چاپ.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ  ,`,
	},
	{
		id: 1,
		created_data: "2024-04-30T14:44:51.000000Z",
		title: "اولین جشنواره زیبایی خراسان",
		body: ` ورم ایپسوم متن ساختگی با تولید سادگی نامفهم
	از صنعت چاپ.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ  ,`,
	},
	{
		id: 4,
		created_data: "2024-04-30T14:44:51.000000Z",
		title: "تجلیل از از هیئت مدیره ",
		body: ` ورم ایپسوم متن ساختگی با تولید سادگی نامفهم
	از صنعت چاپ.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ  ,`,
	},
	{
		id: 5,
		created_data: "2024-04-30T14:44:51.000000Z",
		title: "تجلیل از از هیئت مدیره ",
		body: ` ورم ایپسوم متن ساختگی با تولید سادگی نامفهم
	از صنعت چاپ.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ  ,`,
	},
	{
		id: 6,
		created_data: "2024-04-30T14:44:51.000000Z",
		title: "تجلیل از از هیئت مدیره ",
		body: ` ورم ایپسوم متن ساختگی با تولید سادگی نامفهم
	از صنعت چاپ.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ  ,`,
	},
	{
		id: 7,
		created_data: "2024-04-30T14:44:51.000000Z",
		title: "تجلیل از از هیئت مدیره ",
		body: ` ورم ایپسوم متن ساختگی با تولید سادگی نامفهم
	از صنعت چاپ.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ  ,`,
	},
];

const Home = () => {
	const isResponsive = useMediaQuery({ query: "(min-width:1024px)" });

	return (
		<MainContainer>
			<section className=" lg:mt-[110vh]">
				<div className="lg:bg-[url('./assets/images/mainbg.jpg')] bg-no-repeat bg-cover lg:h-[113vh] top-0 -z-10 lg:absolute w-full">
					<Banner />
				</div>

				<section className="lg:px-10" id="newsSection">
					<NewsSection />
				</section>

				<section className="lg:px-10">
					<GallerySection />
				</section>

				<section className="lg:px-10">
					<BlogSection />
				</section>

				<section className="lg:px-10">
					<TvSection />
				</section>

				{/********** responsive **********/}
				{!isResponsive && (
					<div className={` w-5/6 m-auto mt-16`}>
						<figure className="bg-[url('./assets/images/mainbg.png')] w-full h-64 bg-no-repeat bg-center bg-cover rounded-2xl"></figure>
						<p className="pt-5 text-white text-justify px-3">
							لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ،
							و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه
							روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای
							شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف
							بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه
							درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می
							طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه
							ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی
							ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری
							موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد{" "}
						</p>
						<div className="pt-3 pr-5">
							<NavLink
								to="/about us"
								className="border-[1px] border-primary rounded-xl text-primary px-3 py-1 "
							>
								درباره ما
							</NavLink>
						</div>
					</div>
				)}

				<section
					className={`lg:p-10 ${isResponsive ? "visible" : "hidden"} `}
				>
					<AboutCart />
				</section>

				<section className="lg:px-10">
					<FeedbackSection />
				</section>

				<section className="lg:px-10">
					<ContactForm />
				</section>
			</section>
		</MainContainer>
	);
};

export default Home;
